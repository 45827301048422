 import logo from '../../images/logo.png';
import React, {useEffect, useState,  useRef } from 'react';
import axios from "axios";
 import { useNavigate } from "react-router-dom";
 import LabelInput from "../Fields/LabelInput";
 import SelectBox from "../Fields/SelectBox";

 import {AM} from '../../utility/ValidationRules';
 import validate from '../../utility/Validate';
 import {logout} from "../../utility/CustomFunctions";
 import Multiselect from 'multiselect-react-dropdown';

 let user=[]

 function Home(){
    const navigate = useNavigate();
     const part = useRef(null);
     const process = useRef(null);
     const brand = useRef(null);

    useEffect(() => {

            if (localStorage.getItem('user') === null) {
                navigate("/devices");
            } else {
                user = localStorage.getItem('user') !== null ? JSON.parse(localStorage.getItem('user')) : []
                getBrand()

            }

    }, []);
    const [data, setMachine] = useState({brand:[],part:[],process:[]});
    const [fill, fillData] = useState({brand:"",part:"",process:"",cc:''});
    const [valid, validateData] = useState({brand:"",part:"",process:"",cc:''});
    const [loader, setLoader] = useState(false);


    // useEffect(() => {
    //     return()=> {
    //         getPart()
    //     }
    // }, [fill.brand]);
    // useEffect(() => {
    //   //  getProcess()
    // }, [fill.part]);
    const getBrand=()=>{
        axios.post('https://apir4prod.bsynapse.com/custom/collectiondata', {
            "index": "ifec",
            "collectionid": "1670257868675",

        })
            .then(function (response) {
                if(response.data.results.success) {
                    let newstatus = {...data, ["brand"]: response.data.results.data,["process"]: [],["part"]:[]};
                    setMachine(newstatus)
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const getPart=(brand,part)=>{
        axios.post('https://apir4prod.bsynapse.com/custom/collectiondata', {
            "index": "ifec",
            "collectionid": "2_1670291973",
            "brandname": brand,
            "type":"part"
        })
            .then(function (response) {
                if(response.data.results.success) {
                    console.log(response.data.results.data);
                    let newstatus = {...data, ["part"]: response.data.results.data,["process"]: []};

                    setMachine(newstatus)



                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    const getProcess=(b,p)=>{
        axios.post('https://apir4prod.bsynapse.com/custom/collectiondata', {
            "index": "ifec",
            "collectionid": "1670296012498",
            "brandname": b,
            "partno":p
        })
            .then(function (response) {
                if(response.data.results.success) {
                    console.log(response.data.results.data);
                    let newstatus = {...data, ["process"]: response.data.results.data};
                    setMachine(newstatus)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function setType(e,n){

        if(n === "brand"){
            let ns = {...fill, [n]:e[0].category_name};
            console.log(ns)
            fillData(ns)
            getPart(ns.brand)
        }
        if(n === "part"){
            let ns = {...fill, [n]:e[0].part_number,['process']:''};
            fillData(ns)
            getProcess(ns.brand,ns.part)
        }

        if(n === "process"){
            let ns = {...fill, [n]:e[0].process};
            fillData(ns)
        }
        if(n === undefined){
            let ns = {...fill, [e.target.name]:e.target.value};
            fillData(ns)
        }
    }


    function SaveData() {
        const {validatedData, hasError} = validate(AM, fill);
        validateData(validatedData)
        console.log(validatedData)
        if (!hasError){

            let param = {
                "index": "ifec",
                "cid": "1670303414643",
                "data": [{
                    "userid": user[0].uid,
                    "uname": user[0].user_name,
                    "mid": user[0].machine,
                    "part": fill.part,
                    "process": fill.process,
                    "completed_count": fill.cc,
                }],
                "reference": "entity",
                "referenceid": "",
                "uid": user[0].uid
            }
        axios.post('https://apir4prod.bsynapse.com/collection/saverecord', param)
            .then(function (response) {
                if (response.data.results.success) {

                    fillData({brand:"",part:"",process:"",cc:''})


                    setLoader(true)
                    part.current.resetSelectedValues()
                    process.current.resetSelectedValues()
                    brand.current.resetSelectedValues()
                }
            })
            .catch(function (error) {
                console.log(error);
            });
         }

    }

    function brandRemove(){
         part.current.resetSelectedValues()
        process.current.resetSelectedValues()

    }
console.log(fill)
console.log(fill.brand)
    return(
        <div className="wrapper">
            <div className="form-page-cover">
                <div className="form-wrapper">
                    <figure className="brand-logo">
                        <img src={logo} alt="logo"/>
                        <button className="logout-btn" onClick={()=>      logout()}/>
                    </figure>

                    <div className={`form-content  ${loader?'hide':''}`} >

                            {/*<div className="each-text-field">*/}
                            {/*    <label>Brand</label>*/}
                            {/*    <div className="select-box">*/}
                            {/*        <select name={'brand'}  value={fill.brand} onChange={(e)=>setType(e)}>*/}
                            {/*            <option>Please Select</option>*/}
                            {/*            {data.brand.map((list,ix)=>*/}
                            {/*            <option key={`b-n${ix}`} value={list.category_name}>{list.category_name}</option>*/}
                            {/*            )}*/}
                            {/*        </select>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        {/*<SelectBox*/}
                        {/*    name={'brand'}*/}
                        {/*    label={`Brand`}*/}
                        {/*    onSelect={setType}*/}
                        {/*    data={data.brand}*/}
                        {/*    value={fill.brand}*/}
                        {/*    error={valid.branderror}*/}
                        {/*    displayLabel={'category_name'}*/}
                        {/*/>*/}


                        <div className="each-text-field">
                            <label>Brand</label>
                            <Multiselect

                                options={data.brand} // Options to display in the dropdown
                                onSelect={(e)=>setType(e,"brand")}// Function will trigger on select event
                                onRemove={brandRemove} // Function will trigger on remove event
                                displayValue="category_name" // Property name to display in the dropdown options
                                selectionLimit	={`1`}
                                closeOnSelect	={true}
                                ref={brand}
                            />
                        </div>


                            {/*<div className="each-text-field">*/}
                            {/*    <label>Part</label>*/}
                            {/*    <div className="select-box">*/}
                            {/*        <select name={'part'}  onChange={(e)=>setType(e)}>*/}
                            {/*            <option>Please Select</option>*/}
                            {/*            {data.part.map((list,ix)=>*/}
                            {/*                <option key={`b-n${ix}`} value={list.part_number}>{list.part_number}</option>*/}
                            {/*            )}*/}
                            {/*        </select>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        {/*<SelectBox*/}
                        {/*    name={'part'}*/}
                        {/*    label={`Part`}*/}
                        {/*    onSelect={setType}*/}
                        {/*    data={data.part}*/}
                        {/*    value={fill.part}*/}
                        {/*    error={valid.parterror}*/}
                        {/*    displayLabel={'part_number'}*/}
                        {/*/>*/}


                        <div className="each-text-field">
                            <label>Part</label>
                            <Multiselect

                                options={data.part} // Options to display in the dropdown
                                // selectedValues={fill.part} // Preselected value to persist in dropdown
                                onSelect={(e)=>setType(e,"part")}// Function will trigger on select event
                                 onRemove={()=>process.current.resetSelectedValues()} // Function will trigger on remove event
                                displayValue="part_number" // Property name to display in the dropdown options
                                selectionLimit	={`1`}
                                closeOnSelect={true}
                                ref={part}
                            />
                        </div>



                            {/*<div className="each-text-field">*/}
                            {/*    <label>Process</label>*/}
                            {/*    <div className="select-box">*/}
                            {/*        <select name={'process'} onChange={(e)=>setType(e)}>*/}
                            {/*            <option>Please Select</option>*/}
                            {/*            {data.process.map((list,ix)=>*/}
                            {/*                <option key={`b-n${ix}`} value={list.process}>{list.process}</option>*/}
                            {/*            )}*/}
                            {/*        </select>*/}
                            {/*    </div>*/}
                            {/*</div>*/}



                        {/*<SelectBox*/}
                        {/*    name={'process'}*/}
                        {/*    label={`Process`}*/}
                        {/*    onSelect={setType}*/}
                        {/*    data={data.process}*/}
                        {/*    value={fill.process}*/}
                        {/*    error={valid.processerror}*/}
                        {/*    displayLabel={'process'}*/}
                        {/*/>*/}
                        <div className="each-text-field">
                            <label>Process</label>
                        <Multiselect
                            onSelect={(e)=>setType(e,"process")}// Function will trigger on select event

                            options={data.process} // Options to display in the dropdown
                            // selectedValues={fill.process} // Preselected value to persist in dropdown
                            // onRemove={this.onRemove} // Function will trigger on remove event
                            displayValue="process" // Property name to display in the dropdown options
                            selectionLimit	={`1`}
                            closeOnSelect={true}
                            ref={process}
                        />
                        </div>

                        <LabelInput
                            name={'cc'}
                            label={`Completed Count`}
                            onChangeText={setType}
                            value={fill.cc}
                            error={valid.ccerror}
                        />



                            <div className="submit-block">
                                <button className="general-btn" onClick={()=>SaveData()}>Submit</button>
                            </div>
                    </div>
                    <div className={`action-box ${!loader?'hide':''}`}>
                        <div>
                            <h5>Thank you</h5>
                            <p>Work logged Successfully</p>
                        </div>
                        <div className="button-blocks">
                        <span  className="next-btn" onClick={()=>setLoader(false)}>Add Next</span>
                        </div>

                    </div>
                    <div className="action-box alert hide">
                        <div>
                            <h5>Sorry!</h5>
                            <p>Login Failed</p>
                        </div>
                        <a href="#" className="next-btn">Try Again</a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home
