import React, {useEffect, useState} from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {tc} from '../../utility/ValidationRules';
import validate from '../../utility/Validate';



import logo from '../../images/logo.png';
import {devices} from "../data"
import LabelInput from "../Fields/LabelInput";
function DeviceId(){
    const navigate = useNavigate();

    const [terminal, setTeminal] = useState({tl:''});
    const [valid, validateData] = useState({tl:''});
    const [term, setData] = useState('');

    function addTerminal(e){
        let ns = {...terminal, ["tl"]:e.target.value};
        setTeminal(ns)
    }
    function submit(){
        const {validatedData, hasError} = validate(tc, terminal);
        validateData(validatedData)
        if (!hasError) {


            let f = devices.filter(list => list.device.toString() === terminal.tl.toString())

            if (f.length !== 0) {
                localStorage.setItem('deviceid', JSON.stringify(
                    [
                        {
                            "terminal": terminal.tl,
                            "machine": f[0].machines
                        }
                    ]
                    )
                );

                navigate("/devices");

            } else {

            }
        }

    }
    return(
        <div className="wrapper">
            <div className="full-page-cover">
                <div className="login-wrapper">
                    <figure className="brand-logo">
                        <img src={logo} alt="logo"/>
                    </figure>
                    <div className="login-content">


                        <LabelInput
                            name={'terminal'}
                            label={`Terminal Code`}
                            onChangeText={addTerminal}
                            value={terminal.tl}
                            error={valid.tlerror}
                        />

                        {term && <p className="validation-text">Please Check Terminal Id</p> }





                            <div className="submit-block">
                                <button className="general-btn" onClick={()=>submit()}>Submit</button>
                            </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export  default DeviceId
