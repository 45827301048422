import React, {useEffect, useState} from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";

import logo from '../../images/logo.png';
import {getUrlSegment} from "../../utility/CustomFunctions";
import LabelInput from "../Fields/LabelInput";
import {LG} from '../../utility/ValidationRules';
import validate from "../../utility/Validate";

function Login(){
    const [user, setData] = useState({un:'',pw:''});
    const [valid, validateData] = useState({tl:''});
    const [login, setLogin] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        localStorage.removeItem("user");
    }, []);


    function submit(){
        const {validatedData, hasError} = validate(LG, user);
        validateData(validatedData)

        if(!hasError) {
            axios.post('https://apir4prod.bsynapse.com/login/userlogin', {
                "username": user.un,
                "password": user.pw,
                "index": "ifec"

            })
                .then(function (response) {
                    if (response.data.success) {
                        console.log(response.data.data.userdata.user_id);
                        localStorage.setItem('user', JSON.stringify([{
                            uid: response.data.data.userdata.user_id,
                            machine: getUrlSegment(2),
                            user_name:response.data.data.userdata.user_name
                        }

                        ]));
                        setLogin('')

                        navigate("/");

                    }
                    else{
                        setLogin('error')
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

    }

    function setuserData(e){
        let newstatus = {...user, [e.target.name]:e.target.value};
        setData(newstatus)
    }
    console.log(getUrlSegment(2))
    return(
        <div className="wrapper">
            <div className="full-page-cover">
                <div className="login-wrapper">
                    <figure className="brand-logo">
                        <img src={logo} alt="logo"/>
                    </figure>
                    <div className="login-content">
                        <div className="login-header">
                            <h1>Login</h1>
                        </div>
                        <LabelInput
                            name={'un'}
                            label={`Employee Code`}
                            onChangeText={setuserData}
                            value={user.un}
                            error={valid.unerror}
                        />

                        <LabelInput
                            name={'pw'}
                            label={`Password`}
                            onChangeText={setuserData}
                            value={user.pw}
                            type={'password'}
                            error={valid.pwerror}
                        />
                        {login && <p className="validation-text">Please Check Username or Password</p> }


                        <div className="submit-block">
                                <button className="general-btn" onClick={()=>submit()}>Submit</button>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
