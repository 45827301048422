export const tc = [
    {
        name: 'tl',
        displayName: 'Terminal Code',
        rules: [],
    }
];

export const LG = [
    {
        name: 'un',
        displayName: 'Employee Code',
        rules: [],
    },
    {
        name: 'pw',
        displayName: 'Password',
        rules: [],
    }
];

export const AM = [
    {
        name: 'brand',
        displayName: 'Brand',
        rules: [],
    },
    {
        name: 'part',
        displayName: 'Part',
        rules: [],
    },
    {
        name: 'process',
        displayName: 'Process',
        rules: [],
    },
    {
        name: 'cc',
        displayName: 'Completed Count',
        rules: [],
    }

];

