
export function getUrlSegment(segment) {
    var url = window.location.pathname.split('/');
    return url[segment];
}


export function logout(segment) {

    localStorage.removeItem("user")
    window.location.href = '/devices'
}
