let devices=[{
    "device" :101,
    "machines":[
        {
            "name" :"Shot Blasting",
            "type":	"SB1"
        },
        {
            "name" :"Drilling",
            "type":	"D-3"
        }
    ]
},
    {
        "device" :102,
        "machines":[
            {
                "name" :"Milling",
                "type":	"M-9"
            },
            {
                "name" :"CNC/VMC",
                "type":	"M-29"
            }
        ]
    },
    {
        "device" :103,
        "machines":[
            {
                "name" :"Tempering",
                "type":	"TF-1"
            },
            {
                "name" :"Honning",
                "type":	"H-3"
            }
        ]
    }
]

export {devices}
