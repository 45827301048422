import React from "react";

function LabelInput(props){

    let {label,value,name,error}=props
    let type=props.type === undefined?'text':props.type
    return(
        <div className="each-text-field">
            <label>{label}</label>
            <div className="text-box">
                <input type={type} name={name} value={value} onChange={(e)=> props.onChangeText(e)}/>
                {error && <p className="validation-text">{error}</p> }
            </div>
        </div>
    )
}

export default LabelInput
