import React, {useEffect, useState} from 'react';
import axios from "axios";
import { useNavigate } from "react-router-dom";

import logo from '../../images/logo.png';

function Devices(){
    const navigate = useNavigate();


   let data=localStorage.getItem('deviceid') !== null ?JSON.parse(localStorage.getItem('deviceid')):[]
   let user=localStorage.getItem('user') !== null ?JSON.parse(localStorage.getItem('user')):[]
    return(
        <div className="wrapper">
            <div className="form-page-cover">
                <div className="form-wrapper">
                    <figure className="brand-logo">
                        <img src={logo} alt="logo"/>
                    </figure>
                    <div className="form-content">
                        {data.length !== 0 && data[0].machine.map((list, inx) =>
                            <div className="field-set" key={`f-d-${inx}`}>
                             <label>{list.type} ({list.name})</label>
                                <button className="click-btn" onClick={(e) => navigate('/login/' + list.type)}>
                                    {user.filter(list => list.machine === list.type).length !== 0 ? "GO TO" : "LOGIN"}
                                </button>
                            </div>
                        )}

                    </div>
                </div>
            </div>

        </div>

    )
}

export  default Devices
