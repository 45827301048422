import React from "react";

function SelectBox(props){

    let {name,label,data,error,displayLabel,value}=props
    return(
        <div className="each-text-field">
            <label>{label}</label>
            <div className="select-box">
                    <select name={name} onChange={(e)=>props.onSelect(e)} value={value}>
                        <option>Please Select</option>
                        {data.map((list,ix)=>
                            <option key={`b-n${ix}`} value={list[displayLabel]}>{list[displayLabel]}</option>
                        )}
                    </select>
                {error && <p className="validation-text">{error}</p> }

            </div>
        </div>
    )
}

export default SelectBox
