export default function validate(fields, data) {
    let validatedData = {...data};
    validatedData.errorMsg = '';
    let error = false, inValidField = false;
    for (let i = 0; i < fields.length; i++) {
        let field = fields[i];
        let fieldName = field.name;
        let fieldDisplayName = field.displayName;
        validatedData[fieldName + 'error'] = undefined;
        if (!data[fieldName] || data[fieldName].toString().trim() === '') {
            error = true;
            validatedData[fieldName + 'error'] = field.errorText ? field.errorText : `${fieldDisplayName} cannot be left blank`;
            continue;
        }
        for (let j = 0; j < field.rules.length; j++) {
            var rule = field.rules[j];
            let ruleKey = Object.keys(rule)[0];
            switch (ruleKey) {
                case 'validEmail':
                    if (!validateEmail(data[fieldName])) {
                        error = true;
                        inValidField = true;
                        validatedData[fieldName + 'error'] = `Please enter a valid ${fieldDisplayName}`;
                    }
                    break;
                case 'minLength':
                    if (data[fieldName].trim().length < rule.minLength) {
                        error = true;
                        inValidField = true;
                        validatedData[fieldName + 'error'] = `${fieldDisplayName} requires minimum ${rule.minLength} characters`;
                    }
                    break;
                case 'maxLength':
                    if (data[fieldName].trim().length > rule.maxLength) {
                        error = true;
                        inValidField = true;
                        validatedData[fieldName + 'error'] = `${fieldDisplayName} requires maximum ${rule.maxLength} characters`;
                    }
                    break;
                case 'minLengthNumber':
                    if (data[fieldName].replace(/\D/g, '').trim().length < rule.minLengthNumber) {
                        error = true;
                        inValidField = true;
                        validatedData[fieldName + 'error'] = `${fieldDisplayName} requires minimum ${rule.minLengthNumber} characters`;
                    }
                    break;
                case 'password':
                    if (!validPassword(data[fieldName])) {
                        error = true;
                        inValidField = true;
                        validatedData[fieldName + 'error'] = 'Please enter password in specified format';
                    }
                    break;
                case 'onlyCharacters':
                    if (!onlyCharacters(data[fieldName])) {
                        error = true;
                        inValidField = true;
                        validatedData[fieldName + 'error'] = `Please enter a valid ${fieldDisplayName}`;
                    }
                    break;
                case 'onlyNumbers':
                    if (!onlyNumbers(data[fieldName])) {
                        error = true;
                        inValidField = true;
                        validatedData[fieldName + 'error'] = `Please enter a valid ${fieldDisplayName}`;
                    }
                    break;
                case 'maxValue':
                    if (parseInt(data[fieldName],10) > rule.maxValue || parseInt(data[fieldName], 10) <= 0) {
                        error = true;
                        inValidField = true;
                        validatedData[fieldName + 'error'] = `Please enter a valid ${fieldDisplayName}`;
                    }
                    break;
                case 'onlyCharactersAndNumbers':
                    if (!onlyCharactersAndNumbers(data[fieldName])) {
                        error = true;
                        inValidField = true;
                        validatedData[fieldName + 'error'] = `Please enter a valid ${fieldDisplayName}`;
                    }
                    break;
            }
            if (validatedData[fieldName + 'error']) {
                break;
            }
        }
    }
    return {validatedData: validatedData, hasError: error, inValidField};
}

function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function validPassword(password) {
    const re = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[@$!-_+=~^<>{}`%#?&()|])[a-zA-Z0-9@$!-_+=~^<>{}`%#?&()|]{5,20}$/;
    return re.test(password);
}

function onlyCharacters(value) {
    return /^[a-zA-Z\s]*$/.test(value);
}

function onlyNumbers(value) {
    return /^[0-9]+$/.test(value);
}

function onlyCharactersAndNumbers(value) {
    return /^[a-zA-Z0-9]+$/.test(value);
}

