import React, {useEffect} from "react";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import Login from "./components/Pages/Login";
import Home from "./components/Pages/Home";
import DeviceId from "./components/Pages/DeviceId";
import Devices from "./components/Pages/Devices";
import { useNavigate } from "react-router-dom";

const MainLayout = ({children}, ...rest) => {
    const navigate = useNavigate();

    useEffect(() => {
        if(localStorage.getItem('deviceid') === null){
            navigate("/deviceid");
        }
        if(localStorage.getItem('deviceid') === null){
            navigate("/deviceid");
        }

    }, []);
    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    );
};
export default function App() {

  return (
      <Router>
          <Routes>
              <Route exact path="/deviceid" element={<MainLayout><DeviceId/></MainLayout>}/>
              <Route exact path="/devices" element={<MainLayout><Devices/> </MainLayout>} />
              <Route exact path="/" element={<MainLayout><Home/></MainLayout> } />
              <Route exact path="/login/:id" element={<Login/> } />

          </Routes>
      </Router>
  );
}

// You can think of these components as "pages"
// in your app.

